#my-page {
  & .change-password-container {
    margin-top: 64px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    & .form-container {
      margin-top: 8px;
    }
  }
  & .change-password-submit-button {
    margin-top: 20px;
    height: 50px;
    font-size: 16px;
  }
}
