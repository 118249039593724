#manuscript-submit-status-detail {
  & .manuscript-submit-status-detail-container {
    margin-top: 50px;
    border: 1px #e5eaeb solid;
    padding: 40px;
    font-size: 12px;
    font-weight: 200;
    & .manuscript-submit-status-detail-table {
      vertical-align: middle;
      border-top: 2px #00182e solid;
      & .content-left {
        height: 38px;
        text-align: left;
        margin-left: 15px;
        padding: 10px 0;
      }
      & .row-container {
        display: flex;
        height: 38px;
        border-bottom: 1px #e5eaeb solid;
      }
      & .col {
        background-color: #f7f7f7;
        width: 150px;
        font-weight: 400;
        padding: 10px 0;
        display: flex;
        & div {
          margin: auto;
          border-bottom: none;
        }
      }
      & .author,
      & .register-date,
      & .view-count {
        width: 200px;
      }
      & .content-container {
        display: flex;
        border-bottom: 1px #e5eaeb solid;
        & .content-text {
          margin: 0;
          padding-left: 15px;
          min-height: 150px;
          width: 960px;
          font-family: "NanumBarunGothic", sans-serif;
          display: table;
        }
      }
    }
    & .manuscript-submit-status-button-container {
      width: 100%;
      height: 32px;
      margin-top: 20px;
      display: flex;
      & .manuscript-submit-status-button {
        width: 100px;
        height: 32px;
        margin-left: auto;
      }
    }
  }
}
