.MuiPaper-root {
  box-shadow: none !important;
  & .MuiTableContainer-root {
    & .MuiBox-root {
      box-shadow: none;
    }
    & table thead tr th.MuiTableCell-root {
      border-top: 2px #00182e solid;
      background-color: #f7f7f7;
      border-bottom: 1px solid #e6e6e6;
    }
    & tfoot {
      background-color: #f7f7f7;
    }
  }
}

.add-data {
  max-height: 600px;
  overflow-y: scroll;
}
