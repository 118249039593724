#outcoming {
  & .search-filter-container {
    padding-top: 10px;
    padding-bottom: 0;
  }
  & .head-container {
    display: flex;
    margin-bottom: 14px;
    & .first-head-container {
      margin-left: 475px;
    }
    & .second-head-container {
      margin-left: auto;
    }
  }
  & .table-container {
    display: flex;
    justify-content: space-between;

    & .first-table-container {
      & .MuiTable-root {
        & .MuiTableCell-root {
          font-size: 14px;
          font-weight: 500;
          &:nth-of-type(3) {
            & div {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        // 총 건수
        & .footer-margin-left {
          margin-left: -50px;
        }
      }
    }
    & .second-table-container {
      & .MuiTable-root {
        & .MuiTableCell-root {
          font-size: 14px;
          font-weight: 500;
          &:nth-of-type(3) {
            & div {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          &:nth-of-type(4) {
            & div {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        // 총 건수
        & .footer-margin-left {
          margin-left: -55px;
        }
      }
    }
  }
  // 엑셀 다운로드 버튼
  .download-button {
    color: #236daa;
    border-color: #236daa;
    background-color: #ffffff;
  }
  // 엑셀 다운로드 버튼 호버링
  .download-button:hover {
    color: #ffffff;
    background-color: #236daa;
  }
  & .download-button[disabled] {
    border: 1px solid #999999;
    background-color: #eee;
    color: #555;
    & u {
      color: #555;
    }
  }
}
