#sign-in-page {
  & .content-wrapper {
    margin: 0 auto !important;
    & .MuiContainer-root {
      max-width: 1400px;
      margin: 0 auto;
      & .sign-in-wrap {
        width: 682px;
        margin: 30px auto 0;
        & .logo-container {
          width: 100%;
          text-align: center;
          padding: 0 0 30px 0;
          & img {
            vertical-align: top;
          }
        }
        & .sign-in-container {
          width: 100%;
          border: 2px #cacaca solid;
          border-radius: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          & .form-title-container {
            font-size: 28px;
            color: #0076b4;
            line-height: 1.2;
            text-align: center;
            padding: 50px 0 0 0;
          }
          & .form-container {
            width: 340px;
            margin: 0 auto;
            overflow: hidden;
            & .search-metax-info-async {
              width: 340px;
              margin-top: 24px;
            }
            & .MuiPaper-root {
              border: 1px solid rgba(0, 0, 0, 0.23);
              & .MuiAutocomplete-listbox {
                overflow-x: hidden;
              }
            }
            & .branch-option-list {
              padding: 0 20px;
              width: 340px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              height: 32px;
              line-height: 32px;
              display: flex;
              cursor: pointer;
            }
            & .sign-in-button {
              width: 100%;
              height: 45px;
              background: #767676;
              border-radius: 5px;
              margin-bottom: 10px;
            }
            & .resetArea {
              text-align: right;
              & .passwordReset {
                font-size: 14px;
                color: #888;
                cursor: pointer;
              }
              :hover {
                text-decoration: underline;
              }
            }
          }
          & .announcement-container {
            margin-top: 15px;
            margin-bottom: 15px;
            & p {
              margin-top: 5px;
              margin-bottom: 5px;
              font-size: 14px;
              color: #888;
            }
          }
        }
      }
      & .banner-container {
        width: 100%;
        margin: 30px 0 30px;

        & .banner-inline-container {
          text-align: center;
          display: flex;
          justify-content: center;
          & * {
            vertical-align: top;
          }
          & .first-banner {
            width: 854px;
            height: 145px;
            margin: 0 22px 0 0;
            border-radius: 20px;
            background: linear-gradient(
              152deg,
              rgba(0, 118, 180, 1) 0%,
              rgba(0, 118, 180, 1) 59%,
              rgba(0, 79, 145, 1) 100%
            );
            & a {
              display: block;
              width: 100%;
              height: 100%;
              & .bannerM {
                position: relative;
                width: 100%;
                height: 100%;
                overflow: hidden;
                text-align: left;
                & .bmLogo {
                  display: inline-block;
                  padding: 7px 68px 7px 78px;
                  border-right: 1px #6da7cc solid;
                  margin: 30px 0 0 0;
                }
                & .bTxt {
                  position: absolute;
                  left: 313px;
                  top: 30%;
                  & .tt {
                    font-size: 26px;
                    color: #fff;
                    font-weight: 600;
                  }
                  & .tx {
                    font-size: 18px;
                    color: #fff;
                    padding: 5px 0 0 0;
                  }
                }
                & .bGo {
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  width: 140px;
                  height: 40px;
                  text-align: center;
                  & span {
                    position: relative;
                    display: inline-block;
                    padding: 0 20px 0 0;
                    font-size: 16px;
                    color: #fff;
                    line-height: 44px;
                    z-index: 2;
                  }
                  & span:after {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 50%;
                    display: block;
                    width: 10px;
                    height: 10px;
                    margin: -7px 0 0 0;
                    border-top: 2px solid #fff;
                    border-left: 2px solid #fff;
                    transform: rotate(135deg);
                  }
                }
                & .bGo:after {
                  content: "";
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  background: #000;
                  opacity: 0.5;
                  z-index: 1;
                  border-radius: 20px 0;
                }
              }
            }
          }
          & .second-banner {
            width: 420px;
            height: 145px;
            background: #cfeaff;
            margin: 0;
            border-radius: 20px;
            & a {
              display: block;
              width: 100%;
              height: 100%;
              text-decoration: none;
              & .bannerS {
                position: relative;
                width: 100%;
                height: 100%;
                overflow: hidden;
                text-align: left;
                & .bTxt2 {
                  position: absolute;
                  left: 44px;
                  top: 40%;
                  margin: -25px 0 0 0;
                  & p {
                    font-size: 18px;
                    color: #2a2a2a;
                    line-height: 50px;
                    padding: 0 0 0 55px;
                    background: url(../imgs/kpc-small-logo.png) left 50%
                      no-repeat;
                  }
                }
                & .bGo {
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  width: 140px;
                  height: 40px;
                  text-align: center;
                  & span {
                    position: relative;
                    display: inline-block;
                    padding: 0 20px 0 0;
                    font-size: 17px;
                    color: #fff;
                    line-height: 44px;
                    z-index: 2;
                  }
                  & span:after {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 50%;
                    display: block;
                    width: 10px;
                    height: 10px;
                    margin: -7px 0 0 0;
                    border-top: 2px solid #fff;
                    border-left: 2px solid #fff;
                    transform: rotate(135deg);
                  }
                }
                & .bGo:after {
                  content: "";
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  background: #9bc2e6;
                  opacity: 1;
                  z-index: 1;
                  border-radius: 20px 0;
                }
              }
            }
          }
        }
      }
    }
  }
  & .footer-container {
    display: none;
  }
  & .login-footer {
    width: 100%;
    font-size: 12px;
    color: #333;
    line-height: 1.6;
    padding: 25px 0 94px 0;
    text-align: center;
    border-top: 1px #e5eaeb solid;
    clear: both;
    overflow: hidden;
    & p {
      font-size: 12px;
      color: #333;
      line-height: 1.6;
      margin: 0;
    }
  }
}

// 최초 로그인 정보 입력 모달
#modal-container {
  #publisher-info-insert-modal-container {
    width: 650px;
    & .title-container {
      width: 100%;
      padding: 22px 0 22px 0 !important;
      margin: 0 0 25px 0;
      border-bottom: 1px #e6e6e6 solid;
      overflow: hidden;
      & .title {
        & span {
          display: block;
          text-align: left;
          font-size: 28px;
          color: #333;
          line-height: 1.1;
        }
      }
      & .closed {
        position: absolute;
        right: 35px;
        top: 27px;
        z-index: 100;
        & a {
          display: block;
          width: 40px;
          height: 40px;
          background: url(../imgs/close-button.png) left top no-repeat;
        }
        & a:hover {
          cursor: pointer;
        }
      }
    }
    & .info-input-container {
      border-top: 2px #000000 solid;
      margin-bottom: 20px;
      & table {
        width: 100%;
        & strong {
          font-weight: 600;
          display: block;
          font-size: 14px;
          line-height: 1.4;
          padding: 5px 0;
        }
        & th {
          width: 120px;
          height: 44px;
          padding: 0 10px 0 15px;
          background: #f7f7f7;
          font-size: 12px;
          text-align: left;
          border-bottom: 1px #e6e6e6 solid;
        }
        & td {
          width: 200px;
          height: 44px;
          padding: 5px 10px 5px 10px;
          line-height: 1.2;
          font-size: 12px;
          text-align: left;
          color: #666;
          border-bottom: 1px #e6e6e6 solid;
        }
        & .MuiInputBase-root {
          height: 32px;
          width: 100%;
          & .MuiOutlinedInput-notchedOutline {
            border: 1px #ccc solid;
          }
          & input {
            height: 32px;
            padding: 0 10px 0 10px;
            color: #333;
            line-height: 32px;
            width: 100%;
            vertical-align: middle;
          }
        }
      }

      // 연락처 inputbox 숫자+-버튼 제거용
      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    // 하단 저장 버튼 영역
    & .footer-button-container {
      & button {
        margin-left: 10px;
        width: 102px;
        font-size: 12px;
        height: 32px;
        border: 1px #c4d5e1 solid;
        text-decoration: none;
        font-size: 14px;
        color: #236daa;
        font-weight: 600;
        line-height: 32px;
      }
      button:hover {
        color: #ffffff;
        background-color: #236daa;
      }
    }
  }
}
