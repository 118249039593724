#book-management {
  // 검색 영역
  & .search-filter-container {
    margin-bottom: 20px;
    padding: 15px 20px;
  }

  // 일괄버튼 영역
  & .header-button-container {
    display: flex;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 14px;
    & .select-options {
      width: 103px;
      height: 32px;
      margin-right: 10px;
      margin-left: auto;
    }
    & button {
      width: 104px;
      height: 32px;
    }
    // 엑셀 다운로드 버튼 호버링
    & button:hover {
      color: #ffffff;
      background-color: #236daa;
    }
    & .download-button {
      margin-left: 10px;
    }
    & button[disabled] {
      border: 1px solid #999999;
      background-color: #eee;
      color: #555;
    }
  }

  // 데이터 테이블 영역
  & .book-status-update {
    & .MuiTable-root {
      & .MuiTableCell-root {
        font-size: 14px;
        font-weight: 500;
        &:first-of-type {
          width: 40px !important;
          text-align: center;
        }
        &:nth-of-type(3) {
          & div {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &:nth-of-type(4) {
          & div {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &:nth-of-type(5) {
          & div {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &:nth-of-type(10) {
          & .MuiSelect-select {
            font-size: 14px;
            font-weight: 500;
          }
        }
        &:nth-of-type(11) {
          & .MuiInputBase-input {
            font-size: 14px;
            font-weight: 500;
          }
          // 셀렉트박스 붉은 테두리 없애기
          & .MuiOutlinedInput-notchedOutline {
            border-color: rgba(0, 0, 0, 0.26);
          }
        }
      }
      & tbody {
        & tr {
          & td {
            & .select-options {
              height: 40px;
              width: 90px;
            }
            & .date-picker {
              width: 148px;
              & .MuiInputBase-root {
                height: 40px;
              }
            }
          }
        }
      }
    }
    & .submit-button-container {
      margin-top: 40px;
      & button {
        width: 100px;
        height: 32px;
      }
    }

    & .MuiOutlinedInput-notchedOutline {
      padding: 0px;
    }
  }
}

// 수정 모달
.update-book-information-modal {
  & .innerBox {
    padding: 52px 44px 50px 44px;
    & #guHead {
      width: 100%;
      padding: 0 0 22px 0;
      margin: 0 0 25px 0;
      border-bottom: 1px #e6e6e6 solid;
      overflow: hidden;
      & span {
        display: block;
        font-size: 28px;
        color: #333;
        line-height: 1.1;
        float: left;
      }
      & .guClosed {
        float: right;
        & a {
          display: block;
          width: 40px;
          height: 40px;
          background: url(../../imgs/close-button.png) left top no-repeat;
          cursor: pointer;
        }
      }
    }
    & .popAddNoti {
      font-size: 14px;
      color: #666;
      line-height: 1.2;
      padding: 0 0 12px 0;
      text-align: left;
    }
    & .tbList {
      vertical-align: middle;
      border-top: 2px #00182e solid;
      text-align: left;
      & table {
        width: 100%;
        padding: 0 5px 0 0;
        & tbody {
          & tr {
            & th {
              vertical-align: middle;
              height: 38px;
              background: #f7f7f7;
              border-bottom: 1px solid #e6e6e6;
              padding: 10px 5px 9px 15px;
              min-width: 100px;
            }
            & td {
              vertical-align: middle;
              font-size: 12px;
              color: #666;
              line-height: 1.2;
              border-bottom: 1px #e4ebf1 solid;
              padding: 10px 10px 9px 15px;
              text-align: left;
              & .res {
                width: 100%;
                & ul {
                  margin: 0;
                  padding: 0;
                  border: 0;
                  list-style: none;
                  & li {
                    float: left;
                    width: 50%;
                    padding: 0;
                  }
                  & li:last-child {
                    padding-left: 10px;
                  }
                }
              }
              & input {
                width: 100%;
                height: 32px;
                font-size: 12px;
                font-weight: 500;
                color: #333;
                background-color: #fff;
                line-height: 32px;
                padding: 0 0 0 10px;
                border: 1px #ccc solid;
                margin: 0;
                box-sizing: border-box;
              }
              & input:disabled {
                opacity: 1;
                -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
              }
              & .select-options {
                width: 100%;
                height: 32px;
                & .MuiSelect-select {
                  font-size: 12px;
                  font-weight: 500;
                }
              }
              & .changePrice {
                width: 100%;
                margin: 5px 0;
                & ul {
                  margin: 0;
                  padding: 0;
                  border: 0;
                  list-style: none;
                  width: 567px;
                  height: 32px;
                  vertical-align: middle;
                  & li {
                    position: relative;
                    float: left;
                    width: 50%;
                    padding: 0 0 0 90px;
                    box-sizing: border-box;
                    & .tt {
                      position: absolute;
                      left: 6px;
                      top: 50%;
                      font-size: 12px;
                      color: #333;
                      line-height: 32px;
                      font-weight: 600;
                      margin: -15px 0 0 0;
                    }
                    & .ess:after {
                      content: "*";
                      position: absolute;
                      right: -10px;
                      top: 6px;
                      font-size: 11px;
                      color: #ff0000;
                      line-height: 1.2;
                      font-weight: 600;
                    }
                  }
                  & li:nth-child(2) {
                    & .tt {
                      left: 30px;
                    }
                  }
                  & .date-picker {
                    width: 194px;
                    & .MuiInputBase-root {
                      & input {
                        border: none;
                      }
                    }
                  }
                }
                & .redNoti {
                  width: 100%;
                  padding: 12px 0 5px 0;
                  & p {
                    font-size: 12px;
                    color: #ff0000;
                    line-height: 1.4;
                  }
                }
              }
            }
          }
        }
      }
    }
    & .btnArea {
      min-height: 32px;
      padding: 20px 0 0 0;
      text-align: center;
      & button {
        height: 32px;
        min-width: 80px;
        margin: 0 8px;
        border-color: #236daa;
        color: #236daa;
        background-color: #ffffff;
      }
      & button:hover {
        color: #ffffff;
        background-color: #236daa;
      }
      & button[disabled] {
        border: 1px solid #999999;
        background-color: #eee;
        color: #555;
      }
    }
  }
}
