#lnb {
  text-align: left;
  min-width: 200px;
  height: fit-content;
  border-top: 2px #333 solid;
  overflow: hidden;
  margin-left: auto;
  & a {
    text-decoration: none;
    display: block;
  }
  & ul {
    list-style: none;
    padding: 0;
    margin: 0;
    & .parent-menu-container {
      border-bottom: 2px #e5e5e5 solid;
      & .parent-menu {
        display: block;
        font-size: 16px;
        color: #444;
        line-height: 36px;
        padding: 0 0 0 10px;
        border-bottom: 1px #e5e5e5 solid;
      }
      & .child-menu-container {
        padding: 12px 0 10px 0;
        & .child-menu {
          display: flex;
          font-size: 14px;
          line-height: 1.2;
          padding: 2px 0 3px 25px;
          font-weight: 500;
          & .child-menu-link {
            width: 150px;
            color: #666;
          }
          & .right-arrow {
            margin: auto 0;
            & img {
              display: none;
            }
          }
        }
        & .child-menu:hover,
        & .child-menu.selected {
          & .child-menu-link {
            color: #236daa;
          }
          & img {
            display: block;
          }
        }
      }
    }
    & .parent-menu-container:nth-child(5) {
      & .child-menu-container {
        & .child-menu:nth-child(3) {
          & .child-menu-link {
            cursor: pointer;
          }
        }
      }
    }
  }
}
