#forwarding {
  & .search-filter-container {
    padding-top: 10px;
    padding-bottom: 0;
  }
  & .search-button-container {
    & button {
      height: 32px;
      margin-left: -570px;
      width: 76px;
      box-shadow: none;
    }
  }
  & .table-head-container {
    margin-bottom: 15px;
    // 엑셀 다운로드 버튼
    & .download-button {
      margin-left: auto;
      color: #236daa;
      border-color: #236daa;
      background-color: #ffffff;
    }
    // 엑셀 다운로드 버튼 호버링
    & .download-button:hover {
      color: #ffffff;
      background-color: #236daa;
    }
    & .download-button[disabled] {
      border: 1px solid #999999;
      background-color: #eee;
      color: #555;
      & u {
        color: #555;
      }
    }
  }
  & .detail-table-header {
    height: 32px;
    font-weight: 400;
    font-size: 18px;
    margin-top: 30px;
    display: flex;
    height: 62px;
    padding: 10px 0 20px;
    line-height: 32px;
    color: #444444;
    font-weight: 200;
    // 엑셀 다운로드 버튼
    & .download-button {
      margin-left: auto;
      color: #236daa;
      border-color: #236daa;
      background-color: #ffffff;
    }
    // 엑셀 다운로드 버튼 호버링
    & .download-button:hover {
      color: #ffffff;
      background-color: #236daa;
    }
    & .download-button[disabled] {
      border: 1px solid #999999;
      background-color: #eee;
      color: #555;
      & u {
        color: #555;
      }
    }
    & .detail-table-header {
      font-weight: 400;
      font-size: 18px;
      margin-top: 30px;
    }
  }
}
