#refund-request {
  & .search-filter-container {
    flex-wrap: wrap;
    & .search-filter-presenter {
      & .search-filter:nth-child(1) {
        & .search-filter-component {
          & .input-field {
            height: 28px;
            top: 4px;
            & label {
              color: #333;
              font-weight: 500;
              font-size: 20px;
              top: 11px;
            }

            & .MuiInputBase-root {
              & input {
                display: none;
              }
              & fieldset {
                background-color: #ccc;
                & legend {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    & .search-button-container {
      padding: 0;
      margin-top: 20px;
    }
  }
  // 버튼 영역
  & .header-button-container {
    //width: 1200px;
    display: flex;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 14px;
    //float: right;
    & button {
      margin-left: auto;
      width: 104px;
      height: 32px;
    }
    // 엑셀 다운로드 버튼 호버링
    & button:hover {
      color: #ffffff;
      background-color: #236daa;
    }
    & button[disabled] {
      border: 1px solid #999999;
      background-color: #eee;
      color: #555;
    }
  }

  // 창고반출 테이블 영역
  & .wms-refund-request {
    & .MuiTable-root {
      & .MuiTableHead-root {
        & .MuiTableRow-root {
          height: 35.67px;
          & .MuiTableCell-root {
            & .MuiBox-root {
              justify-content: center;
            }
          }
        }
      }
      & .MuiTableCell-root {
        font-size: 14px;
        font-weight: 500;
        &:first-of-type {
          width: 15px !important;
          text-align: center;
        }
        &:nth-of-type(2) {
          width: 47px;
        }
        &:nth-of-type(3) {
          width: 80px;
        }
        &:nth-of-type(4) {
          width: 33px;
        }
        &:nth-of-type(5) {
          width: 30px;
        }
        &:nth-of-type(6) {
          width: 35px;
        }
        &:nth-of-type(7) {
          width: 30px;
        }
        &:nth-of-type(8) {
          width: 30px;
          & input {
            text-align: right;
            font-size: 14px;
            font-weight: 500;
            border: 1px solid rgba(0, 0, 0, 0.26);
            height: 25px;
            padding: 5px;
          }
        }
        // 반품기한이었던 것
        // &:nth-of-type(9) {
        //   width: 62px;
        //   & .date-picker {
        //     width: 123px;
        //     & input {
        //       padding-left: 5px;
        //       font-weight: 500;
        //       font-size: 12px;
        //     }
        //   }
        // }
        &:nth-of-type(9) {
          width: 50px;
          & .select-options {
            width: 100%;
            & .MuiSelect-select {
              font-size: 12px;
              font-weight: 500;
            }
            & fieldset {
              width: 115px;
            }
          }
        }
        &:nth-of-type(10) {
          width: 50px;
          & .select-options {
            width: 100%;
            & .MuiSelect-select {
              font-size: 12px;
              font-weight: 500;
            }
            & fieldset {
              width: 115px;
            }
          }
        }
        &:nth-of-type(11) {
          width: 55px;
          & .date-picker {
            width: 123px;
            & input {
              padding-left: 5px;
              font-weight: 500;
              font-size: 12px;
            }
          }
        }
      }
      & tbody {
        & tr {
          & td {
            & .select-options {
              height: 40px;
              width: 90px;
            }
            & .date-picker {
              width: 148px;
              & .MuiInputBase-root {
                height: 40px;
              }
            }
          }
        }
      }
    }
  }

  // 서점반출 테이블 영역
  & .store-refund-request {
    & .MuiTable-root {
      & .MuiTableHead-root {
        & .MuiTableRow-root {
          height: 35.67px;
          & .MuiTableCell-root {
            & .MuiBox-root {
              justify-content: center;
            }
          }
        }
      }
      & .MuiTableCell-root {
        font-size: 14px;
        font-weight: 500;
        &:first-of-type {
          width: 15px !important;
          text-align: center;
        }
        &:nth-of-type(2) {
          width: 47px;
        }
        &:nth-of-type(3) {
          width: 80px;
        }
        &:nth-of-type(4) {
          width: 33px;
        }
        &:nth-of-type(5) {
          width: 30px;
        }
        &:nth-of-type(6) {
          width: 35px;
        }
        &:nth-of-type(7) {
          width: 50px;
          & .select-options {
            width: 100%;
            & .MuiSelect-select {
              font-size: 12px;
              font-weight: 500;
            }
            & fieldset {
              width: 115px;
            }
          }
        }
        &:nth-of-type(8) {
          width: 50px;
          & .select-options {
            width: 100%;
            & .MuiSelect-select {
              font-size: 12px;
              font-weight: 500;
            }
            & fieldset {
              width: 115px;
            }
          }
        }
        &:nth-of-type(9) {
          width: 50px;
          & .select-options {
            width: 100%;
            & .MuiSelect-select {
              font-size: 12px;
              font-weight: 500;
            }
            & fieldset {
              width: 115px;
            }
          }
        }
        &:nth-of-type(10) {
          width: 55px;
          & .date-picker {
            width: 123px;
            & input {
              padding-left: 5px;
              font-weight: 500;
              font-size: 12px;
            }
          }
        }
      }
      & tbody {
        & tr {
          & td {
            & .select-options {
              height: 40px;
              width: 90px;
            }
            & .date-picker {
              width: 148px;
              & .MuiInputBase-root {
                height: 40px;
              }
            }
          }
        }
      }
    }
  }

  // 반출요청 버튼
  & .submit-button-container {
    margin-top: 40px;
    & button {
      width: 100px;
      height: 32px;
    }
    & button[disabled] {
      border: 1px solid #999999;
      background-color: #eee;
      color: #555;
    }
  }

  // 테이블 안의 셀렉트박스 padding
  & .MuiOutlinedInput-notchedOutline {
    padding: 0px;
  }
}
