.list-container {
  padding: 20px 40px 40px;
  border: 1px #e5eaeb solid;
  width: fit-content;
  display: flex;
  flex-direction: column;
  & .list-title {
    width: 100%;
    font-size: 18px;
    display: flex;
    color: #444444;
    margin-bottom: 7px;
    & .more-button {
      margin-left: auto;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    & .title-text {
      line-height: 27px;
      margin-left: auto;
      font-size: 15px;
      color: #236daa;
    }
  }
  & .list-body {
    text-align: center;
    vertical-align: middle;
    border-top: 2px #00182e solid;
    height: auto;
    & .list-header {
      display: flex;
      & .column {
        height: 38px;
        background: #f7f7f7;
        border-bottom: 1px solid #e6e6e6;
        line-height: 38px;
        font-size: 15px;
      }
    }
    & .list-content {
      font-weight: 200;
      font-size: 12px;
      & a {
        text-decoration: none;
        color: #666;
      }
      & a:hover {
        text-decoration: underline;
      }
      & .no-data {
        margin: auto;
        height: 57px;
        line-height: 57px;
      }
      & .data-row {
        display: flex;
        border-bottom: 1px solid #e6e6e6;
        & .row {
          padding: 8px 5px;
          font-size: 14px;
          font-weight: 500;
        }
        & .title {
          text-align: left;
          padding-left: 30px;
        }
      }
    }
  }
  & .notice {
    display: inline-block;
    width: 60px;
    height: 24px;
    font-size: 13px;
    color: #236daa;
    line-height: 24px;
    text-align: center;
    border: 1px #c4d5e1 solid;
    margin: 0 10px -5px 0;
    font-weight: 400;
  }
  // 자료실
  & .reference {
    display: inline-block;
    width: 60px;
    height: 24px;
    font-size: 13px;
    color: #236daa;
    line-height: 24px;
    text-align: center;
    border: 1px #c4d5e1 solid;
    margin: 0 10px -5px 0;
    font-weight: 400;
  }

  & .pagination {
    display: flex;
    margin-top: auto;
    & nav {
      margin: auto;
      & .Mui-selected {
        background-color: #292e42;
        color: white;
      }
      & button {
        border: 1px #ddd solid;
        border-radius: 0;
        margin: 0;
      }

      & li:nth-of-type(2) {
        & button {
          margin-right: 6px;
        }
      }
      & li:nth-last-of-type(2) {
        & button {
          margin-left: 6px;
        }
      }
      & button:hover {
        background-color: #292e42;
        color: white;
      }
    }
  }
}
