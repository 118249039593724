// common
@import "./color.scss";
@import "./DataTable.scss";
@import "./Dropzone.scss";
@import "./ErrorPage.scss";
@import "./fonts.scss";
@import "./Footer.scss";
@import "./Header.scss";
@import "./List.scss";
@import "./Main.scss";
@import "./Modal.scss";
@import "./MyPage.scss";
@import "./SearchFilter.scss";
@import "./SideMenu.scss";
@import "./SignIn.scss";
@import "./TabMenu.scss";
@import "./Test.scss";

// Analysis
@import "./Analysis/SalesHistory.scss";

// Books
@import "./Books/NewBook.scss";
@import "./Books/RefundRequest.scss";
@import "./Books/BookManagement.scss";

// Deals
@import "./Deals/Bills.scss";
@import "./Deals/DealsLedger.scss";
@import "./Deals/PaymentHistory.scss";

// Orders
@import "./Orders/Forwarding.scss";
@import "./Orders/Incoming.scss";
@import "./Orders/Order.scss";
@import "./Orders/Outcoming.scss";
@import "./Orders/Refund.scss";

// Support
@import "./Support/ManagerInfo.scss";
@import "./Support/ManuscriptSubmitStatus.scss";
@import "./Support/ManuscriptSubmitStatusDetail.scss";
@import "./Support/Notice.scss";
@import "./Support/NoticeDetail.scss";
@import "./Support/Reference.scss";
@import "./Support/ReferenceDetail.scss";
@import "./Support/SubmitStatus.scss";

body {
  margin: 0;
  font-family: $NanumBarunGothic;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none; /* Firefox */
  text-align: center;
}

body::-webkit-scrollbar {
  // display: none; /* Chrome, Safari, Opera*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

$blue: #0070f3;

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid $blue;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spinner 1s linear infinite;
  margin: auto;
}

.spinner-container {
  position: fixed;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  z-index: 100001;
}

.skeleton-container {
  display: flex;
  align-items: center;
  width: 1200px;
  height: 655px;
}

.LazyLoad {
  opacity: 0;
  // transition: all 1s ease-in-out;
}

.is-visible {
  opacity: 1;
}

.warning-text {
  color: red;
  height: 24px;
  width: 100%;
}

.blue {
  color: $blue;
}

.page-container {
  min-height: 100vh;
}

.page {
  height: 100%;
}

.content-wrapper {
  width: 100%;
  min-width: 540px;
  margin: 30px 0 90px;
  min-height: 650px;
  display: flex;
}

.content {
  margin: 0 auto 30px 40px;
  width: 1200px;
  & h2 {
    font-size: 28px;
    text-align: left;
    margin: 0;
    border-bottom: 1px #e5e5e5 solid;
    padding: 0 0 20px 0;
    font-weight: 400;
    & span:first-of-type {
      font-weight: 600;
    }
  }
  & .description {
    position: relative;
    font-size: 20px;
    color: #333;
    line-height: 1.2;
    padding: 33px 0;
    text-align: left;
    font-weight: 200;
    & span {
      color: #236daa;
      font-weight: 400;
    }
  }
}

.margin-auto {
  margin: auto;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

tbody {
  font-weight: 400;
}

.option-list {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 32px;
  cursor: pointer;
  & .option-list-item {
    padding: 0 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    height: 32px;
    line-height: 32px;
    display: flex;
    cursor: pointer;
    & div {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:first-of-type {
        width: 150px;
      }
      &:nth-of-type(3) {
        width: 350px;
      }
      &:nth-of-type(5) {
        width: 80px;
        text-align: right;
      }
      &:nth-of-type(7) {
        width: 70px;
      }
      &:nth-of-type(9) {
        width: 80px;
      }
      &:nth-of-type(2),
      &:nth-of-type(4),
      &:nth-of-type(6),
      &:nth-of-type(8) {
        width: 20px;
        text-align: center;
      }
    }
  }
}

.MuiAutocomplete-popper {
  & .MuiAutocomplete-paper {
    & .MuiAutocomplete-listbox {
      & .Mui-focused {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
  }
}

.search-book-async-input {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  & input {
    max-width: 130px !important;
  }
}

.display-none {
  display: none;
}

.ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: inherit;
}
