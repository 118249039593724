#incoming {
  & .search-filter-container {
    margin-bottom: 30px;
    padding-top: 10px;
    padding-bottom: 0;
    & .search-button-container {
      & button {
        box-shadow: none;
      }
    }
  }

  & .table-container {
    margin-bottom: 35px;
    & .table-head-container {
      height: 32px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 14px;
      & .table-title-text {
        font-size: 15px;
        line-height: 32px;
      }
      & .deals-ledger-button {
        margin-left: auto;
        border: 1px #e5eaeb solid;
        border-color: #236daa;
        color: #236daa;
        background-color: #ffffff;
      }
      & .deals-ledger-button:hover {
        color: #ffffff;
        background-color: #236daa;
      }
      // 엑셀 다운로드 버튼
      & .download-button {
        margin-left: 10px;
        color: #236daa;
        border-color: #236daa;
        background-color: #ffffff;
      }
      // 엑셀 다운로드 버튼 호버링
      & .download-button:hover {
        color: #ffffff;
        background-color: #236daa;
      }
      & .download-button[disabled] {
        border: 1px solid #999999;
        background-color: #eee;
        color: #555;
        & u {
          color: #555;
        }
      }
    }

    & .MuiTable-root {
      & .MuiTableCell-root {
        font-size: 14px;
        font-weight: 500;
      }
    }
    & .list-table {
      & .MuiTable-root {
        & .MuiTableFooter-root {
          & .MuiTableCell-alignRight {
            &:nth-of-type(4) {
              padding-right: 8px;
            }
          }
        }
        & .MuiTableCell-alignRight {
          padding-right: 30px;
        }
        // 총 건수
        & .footer-margin-left {
          margin-left: -70px;
        }
      }
    }
    & .detail-table {
      & .MuiTable-root {
        & .MuiTableCell-root {
          &:nth-of-type(4) {
            & div {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        // 총 건수
        & .footer-margin-left {
          margin-left: -50px;
        }
      }
    }
  }
}
