#deals-ledger {
  & .content-wrapper {
    & .content {
      width: 1200px;
      & h2 {
        width: 1400px;
      }
      & .search-filter-container {
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
        width: 1400px;
        & .search-filter-presenter {
          width: auto;
          & .search-filter {
            margin: 0;
          }
          & .search-filter-component {
            width: auto;
          }
        }
        & .search-button-container {
          width: 884px;
          flex-direction: row;
          & button {
            height: 32px;
            min-width: 115px;
            margin-left: 10px;
            margin-right: 0px;
            box-shadow: none;
          }
          & button:nth-of-type(3) {
            margin-left: 320px;
            width: 145px;
          }
          & button:nth-of-type(4) {
            width: 145px;
            & u {
              color: red;
            }
          }
          & button:nth-of-type(4)[disabled] {
            border: 1px solid #999999;
            background-color: #eee;
            color: #555;
            & u {
              color: #555;
            }
          }
        }
      }

      & .header-button-container {
        width: 1400px;
        display: flex;
        margin-bottom: 15px;
        //float: right;

        & button {
          height: 32px;
          margin-left: auto;
          border-color: #236daa;
          color: #236daa;
          background-color: #ffffff;
        }
        & button:hover {
          color: #ffffff;
          background-color: #236daa;
        }
        & .download-button[disabled] {
          border: 1px solid #999999;
          background-color: #eee;
          color: #555;
          & u {
            color: #555;
          }
        }
      }

      & .MuiTable-root {
        table-layout: fixed;
        & .MuiTableCell-root {
          font-size: 14px;
          font-weight: 500;
          &:nth-of-type(4) {
            & div {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          &:nth-of-type(12) {
            & div {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        & thead {
          & tr {
            // 일자
            & th:nth-of-type(1) {
              width: 53px;
            }
            // 구분
            & th:nth-of-type(2) {
              width: 37px;
            }
            // ISBN
            & th:nth-of-type(3) {
              width: 66px;
            }
            // 적요
            & th:nth-of-type(4) {
              width: 130px;
            }
            // 매입부수
            & th:nth-of-type(5) {
              width: 43px;
            }
            // 매입금액
            & th:nth-of-type(6) {
              width: 65px;
            }
            // 반품부수
            & th:nth-of-type(7) {
              width: 43px;
            }
            // 반품금액
            & th:nth-of-type(8) {
              width: 43px;
            }
            // 조정금액
            & th:nth-of-type(9) {
              width: 43px;
            }
            // 지불액
            & th:nth-of-type(10) {
              width: 65px;
            }
            // 잔액
            & th:nth-of-type(11) {
              width: 65px;
            }
          }
        }
        & .monthly-sum-row {
          background-color: lightgrey;
        }
      }
      & .summary-table-container {
        & .MuiTable-root {
          table-layout: fixed;
          & thead {
            & tr {
              // 일자
              & th:nth-of-type(1) {
                width: 52px;
              }
              // 구분
              & th:nth-of-type(2) {
                width: 36px;
              }
              // 적요
              & th:nth-of-type(3) {
                width: 182px;
              }
              // 매입부수
              & th:nth-of-type(4) {
                width: 43px;
              }
              // 매입금액
              & th:nth-of-type(5) {
                width: 65px;
              }
              // 반품부수
              & th:nth-of-type(6) {
                width: 43px;
              }
              // 반품금액
              & th:nth-of-type(7) {
                width: 43px;
              }
              // 조정금액
              & th:nth-of-type(8) {
                width: 43px;
              }
              // 지불액
              & th:nth-of-type(9) {
                width: 65px;
              }
              // 잔액
              & th:nth-of-type(10) {
                width: 65px;
              }
            }
          }
        }
      }
    }
  }
}

#modal-container {
  // width: 660px;
  #compare-modal-container {
    width: 580px;
    height: auto;
    & .innerBox {
      padding: 42px 34px 34px 34px;
      & #guHead {
        width: 100%;
        padding: 0 0 22px 0;
        margin: 0 0 25px 0;
        border-bottom: 1px #e6e6e6 solid;
        overflow: hidden;
        & span {
          display: block;
          font-size: 28px;
          color: #333;
          line-height: 1.1;
          float: left;
        }
        & .guClosed {
          // position: absolute;
          // right: 35px;
          // top: 27px;
          // z-index: 100;
          float: right;
          & a {
            display: block;
            width: 40px;
            height: 40px;
            background: url(../../imgs/close-button.png) left top no-repeat;
            cursor: pointer;
          }
        }
      }
      & .tbList {
        vertical-align: middle;
        border-top: 2px #00182e solid;
        & table {
          border-collapse: collapse;
          border-spacing: 0;
          table-layout: fixed;
          word-break: break-all;
          width: 100%;
          padding: 0 5px 0 0;
          & tr:first-of-type {
            & th {
              vertical-align: middle;
            }
          }

          & th {
            position: relative;
            height: 38px;
            background: #f7f7f7;
            border-bottom: 1px solid #e6e6e6;
            & span {
              display: inline-block;
              font-size: 12px;
              color: #333;
              line-height: 1.2;
              font-weight: normal;
              padding: 3px 0 0 0;
            }
          }
          & td {
            vertical-align: middle;
            font-size: 12px;
            color: #666;
            line-height: 1.2;
            border-bottom: 1px #e6e6e6 solid;
            word-break: break-all;
            & .date-picker {
              width: 293px;
              & div {
                height: 32px;
              }
            }
            & input[type="text"] {
              width: 100%;
              max-height: 32px;
              font-size: 12px;
              color: #333;
              line-height: 32px;
              background: #fff;
              padding: 0 0 0 10px;
              border: 1px #ccc solid;
              margin: 0;
              box-sizing: border-box;
              vertical-align: middle;
              margin: -5px 0 -4px;
            }
            & input[disabled] {
              background-color: #dedede;
              color: #333;
            }
            #dp1667284110199 {
              width: 180px;
            }

            & .memo {
              width: 100%;
              padding: 10px 0 0 0;
              margin: 0 0 -10px 0;
              overflow: hidden;
              & textarea {
                border: 1px solid #ccc;
                overflow-y: auto;
                resize: none;
                border-radius: 0;
                font-weight: 400;
                width: 100%;
                height: 80px;
                font-size: 12px;
                color: #333;
                line-height: 1.4;
                padding: 5px 10px;
              }
              & textarea[disabled] {
                background-color: #dedede;
                color: #333;
              }
            }
          }
          & .memos {
            padding-top: 0px;
            & .memo {
              padding-top: 5px;
            }
          }
          & .center {
            text-align: center !important;
            & .chk {
              position: relative;
              display: inline-block;
              padding: 5px 0;
            }
          }
          & .btn {
            position: relative;
            padding: 10px px 95px 9px 15px;
            & .blueBtn {
              border: none;
              box-shadow: none;
              border-radius: 0;
              overflow: visible;
              cursor: pointer;
              min-width: 76px;
              height: 32px;
              background: #41506e;
              text-align: center;
              margin: 0 0 0 10px;
              padding: 0 10px;
              position: absolute;
              right: 8px;
              top: 5px;
              & span {
                font-size: 12px;
                color: #fff;
                font-weight: 600;
                line-height: 34px;
              }
            }
            & .blueBtn:hover {
              background-color: #236daa;
              & span {
                color: #ffffff;
              }
            }
          }
        }
      }
      & .leftPop {
        text-align: left;
        & th {
          padding: 11px 15px 9px 15px;
          vertical-align: top;
        }
        & td {
          text-align: left;
          padding: 11px 10px 9px 15px;
        }
      }
      & .btnArea {
        position: relative;
        width: 100%;
        min-height: 32px;
        padding: 20px 0 0 0;
        text-align: center;
        & .cBtn {
          box-shadow: none;
          border-radius: 0;
          overflow: visible;
          cursor: pointer;
          min-width: 100px;
          height: 32px;
          background: #fff;
          text-align: center;
          margin: 0 8px;
          padding: 0 12px;
          border: 1px #c4d5e1 solid;
          & span {
            font-size: 14px;
            color: #236daa;
            font-weight: 600;
            line-height: 32px;
          }
        }
        & .cBtn:hover {
          background-color: #236daa;
          & span {
            color: #ffffff;
          }
        }
        & .cBtn[disabled] {
          border: 1px solid #999999;
          background-color: #eee;
          & span {
            color: #555;
          }
        }
        & .cBtn[disabled]:hover {
          cursor: default;
        }
      }
    }
  }
  #sales-statement-modal-container {
    padding: 42px 34px 34px 34px;
    & .title-container {
      width: 100%;
      padding: 0 0 22px 0;
      margin: 0 0 25px 0;
      border-bottom: 1px #e6e6e6 solid;
      overflow: hidden;
      & span {
        display: block;
        font-size: 28px;
        color: #333;
        line-height: 1.1;
        text-align: left;
      }
      & .closed {
        position: absolute;
        right: 50px;
        top: 45px;
        z-index: 100;
        & a {
          display: block;
          width: 45px;
          height: 45px;
          background: url(../../imgs/close-button.png) left top no-repeat;
        }
        & a:hover {
          cursor: pointer;
        }
      }
    }
    .sales-statement-table-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
