#payment-history {
  & .search-filter-container {
    padding-top: 10px;
    padding-bottom: 0;
    & .search-filter-component {
      & input {
        font: inherit;
        border-width: 1px;
        border-color: rgba(0, 0, 0, 0.23);
        color: rgba(0, 0, 0, 0.87);
        cursor: text;
        padding-left: 14px;
        font-weight: 500;
        width: 170px;
      }
      & input:focus {
        border-color: #0982f0;
        outline: none;
      }
    }
    & .search-button-container {
      margin-left: 0;
      & button {
        height: 32px;
        width: 100px;
        margin-left: -755px;
        box-shadow: none;
      }
    }
  }
  & .header-button-container {
    display: flex;
    justify-content: right;
    margin: 15px 0 15px;
    & button {
      width: 125px;
      height: 32px;
      margin-left: 10px;
    }
    // 버튼 호버링
    & button:hover {
      color: #ffffff;
      background-color: #236daa;
    }
  }
  & .title {
    width: 100%;
    text-align: left;
    vertical-align: middle;
    margin: 30px 0 15px;
    font-weight: 400;
  }
  & .deduction-status {
    border-top: 2px #00182e solid;
    width: 100%;
    display: flex;
    font-weight: 200;
    & .row-container {
      font-size: 12px;
      border-bottom: 1px solid #e6e6e6;
      & div {
        flex-basis: 16.6666%;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        font-weight: 500;
      }
      & .grey {
        background-color: #f7f7f7;
        font-weight: 400;
      }
    }
    & .summary-container {
      font-size: 12px;
      border-bottom: 1px solid #e6e6e6;
      & div {
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        font-weight: 500;
      }
      & div:first-of-type {
        flex-basis: 28.5714%;
      }
      & div:nth-of-type(2) {
        flex-basis: 71.4285%;
      }
      & .grey {
        background-color: #f7f7f7;
        font-weight: 400;
      }
    }
  }
  & .deduction-status-container {
    font-weight: 200;
    & .deduction-status {
      border-top: 2px #00182e solid;
      width: 100%;
      display: flex;
      & .row-container {
        font-size: 12px;
        border-bottom: 1px solid #e6e6e6;
        & div {
          flex-basis: 14.2857%;
          height: 36px;
          line-height: 36px;
          font-size: 14px;
          font-weight: 500;
        }
        & .grey {
          background-color: #f7f7f7;
          font-weight: 400;
        }
      }
      & .summary-container {
        font-size: 12px;
        border-bottom: 1px solid #e6e6e6;
        & div {
          height: 36px;
          line-height: 36px;
          font-size: 14px;
          font-weight: 500;
        }
        & div:first-of-type {
          flex-basis: 28.5714%;
        }
        & div:nth-of-type(2) {
          flex-basis: 71.4285%;
        }
        & .grey {
          background-color: #f7f7f7;
          font-weight: 400;
        }
      }
    }
  }
  & .book-payment-container {
    font-weight: 200;
    & .book-payment {
      font-size: 12px;
      border-top: 2px #00182e solid;
      & .row-container {
        border-bottom: 1px solid #e6e6e6;
        & div {
          flex-basis: 25%;
          height: 36px;
          line-height: 36px;
          font-size: 14px;
          font-weight: 500;
        }
        & .grey {
          background-color: #f7f7f7;
          font-weight: 400;
        }
      }
    }
  }
  & .notification-text {
    text-align: left;
    font-size: 12px;
    margin-top: 20px;
    & p {
      margin: 0;
      font-weight: 200;
    }
  }
}
