#order {
  & .search-filter-container {
    & .search-button-container {
      & button {
        height: 32px;
      }
    }
  }
  & .header-button-container {
    display: flex;
    margin-top: 20px;
    margin-bottom: 5px;
    float: right;
    width: 1200px;
    & .search-filter {
      display: flex;
      & .search-filter-title-text {
        line-height: 32px;
      }
      & .search-filter-component {
        display: flex;
        width: 360px;
        height: 32px;
        margin-bottom: 10px;
        margin-right: auto;
        & .MuiInputBase-root {
          height: 32px;
          & .MuiInputBase-input {
            height: 32px;
            line-height: 32px;
          }
        }
        & .date-picker {
          height: 32px;
          width: 150px;
          margin: 0 10px;
          background-color: white;
          & input {
            padding-top: 8.5px;
            padding-bottom: 8.5px;
          }
        }
        & .full-range {
          width: 340px;
        }
        & .range-text {
          width: 20px;
          line-height: 36px;
        }
      }
    }
    // 도서명 영역
    & .search-filter-text {
      display: flex;
      line-height: 32px;
      margin-left: 10px;
      // 데이터 영역
      & .search-filter-component {
        margin-left: 10px;
        & .search-book-async {
          width: 300px;
          & .MuiInputBase-root {
            padding: 0px 14px;
            height: 31px;
            margin-top: 1px;
            & input {
              padding-left: 0px;
              font-size: 14px;
              font-weight: 500;
              max-width: 90% !important;
            }
            & .MuiOutlinedInput-notchedOutline {
              padding: 0px;
            }
          }
        }
      }
    }
    & .button {
      width: 100px;
      height: 32px;
      margin-left: 10px;
      &:nth-of-type(1) {
        background: #41506e;
        color: white;
        box-shadow: none;
      }
      &:nth-of-type(2) {
        margin-left: auto;
        padding: 0;
      }
    }
    & .button:hover {
      color: #ffffff;
      background-color: #236daa;
    }
    & .download-button {
      margin-left: auto;
      color: #236daa;
      height: 32px;
      border-color: #236daa;
      background-color: #ffffff;
    }
    & .download-button:hover {
      color: #ffffff;
      background-color: #236daa;
    }
    & .download-button[disabled] {
      border: 1px solid #999999;
      background-color: #eee;
      color: #555;
      & u {
        color: #555;
      }
    }
  }
  & .header-button-container:nth-of-type(2) {
    margin-top: 0px;
    margin-bottom: 15px;
    & .date-select-button {
      margin: 0 9px 0;
      background: #41506e;
      color: white;
      box-shadow: none;
      width: 100px;
      height: 32px;
    }
    & .date-select-button:first-of-type {
      margin-left: 65px;
    }
  }
  & .MuiTable-root {
    table-layout: fixed;
    border-collapse: separate;
    & .MuiTableCell-root {
      font-size: 14px;
      font-weight: 500;
      &:nth-of-type(4) {
        & div {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &:nth-of-type(5) {
        & div {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    // 총 건수
    & .footer-margin-left {
      margin-left: -40px;
    }
  }
}
