#notice {
  & .list-container {
    margin-top: 20px;
    padding-top: 40px;
    height: 484px;
  }
  & .list-body {
    height: auto;
    & div {
      font-size: 14px;
      font-weight: 500;
    }
  }
}
