#reference {
  // 검색 영역
  & .search-filter-container {
    margin-bottom: 20px;
    padding: 15px 20px;
    height: 77px;
    & .search-button-container {
      & button {
        height: 32px;
        width: 100px;
        margin-left: -570px;
      }
    }
  }
  & .header-button-container {
    display: flex;
    margin: 30px 0 15px;
    & button {
      width: 125px;
      height: 32px;
      margin-right: 10px;
    }
  }
  // 데이터 테이블 영역
  & .list-container.margin-auto {
    width: auto;
    padding: 40px;
    & .list-title {
      margin: 0;
    }
    & .list-body {
      width: auto;
      & .list-header,
      & .list-content {
        width: auto;
      }
    }
    & .pagination {
      margin-top: 30px;
    }
  }
}
