.header-wrapper {
  display: flex;
  width: 100%;
  border-bottom: 1px solid black;
  background-color: #124e82;
  & .header-container {
    margin: auto;
    display: flex;
    padding: 5.5px;
    color: white;
    min-width: 480px;
    width: min(1440px, 100vw);
    & .header-button {
      margin: 0 10px 0 10px;
      text-decoration-line: none;
      color: white;
    }
    & :nth-child(2) {
      margin-left: auto;
    }
    & .user-name-text {
      line-height: 44px;
    }
    & .menu-line {
      color: white;
      height: 60px;
      line-height: 60px;
      margin-left: 20px;
    }
    & button,
    div {
      font-size: 12px;
      font-weight: 400;
    }
  }

  .menu-container {
    display: flex;
    padding: 10px;
    background-color: white;
    height: 72.5px;
    & button {
      background-color: white;
      min-width: 150px;
      z-index: 1000;
    }
    & button:hover {
      background-color: white;
    }
  }

  .header-logo {
    cursor: pointer;
  }
}
