#modal-container {
  position: absolute;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #000;
  border-radius: 4px;
  padding: 20px;
  &:has(#notice-modal-container),
  &:has(#sale-stop-modal-container) {
    padding: 0;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 5px 10px #00000050;
  }
}

#sign-out-modal {
  text-align: center;
  background-color: white;
  width: 300px;
  height: 100px;
  & .confirm-text {
    color: black;
    font-size: 18px;
    margin: 20px 20px 30px 20px;
  }
  & .sign-out-button-group {
    & .sign-out-button {
      margin: 0 10px;
      background-color: #236daa;
      color: white;
      width: 80px;
    }
  }
}

#main-test-modal {
  padding: 20px;
}
