#main-page {
  & .button-container {
    button {
      margin: 0 20px;
    }
  }
  & .main-container {
    margin: auto;
    & .second-lists-container {
      width: 1163px;
      display: flex;
      margin: 40px auto;
      justify-content: space-between;
      & .list-container {
        & .list-title {
          & .main-right-arrow {
            cursor: pointer;
            position: relative;
            left: 400px;
            top: 2px;
          }
        }
      }
    }
  }
}

#notice-modal-container {
  // cursor: pointer;
  // width: 400px;
  // height: 700px;
  & .notice-img {
    border-radius: 8px;
    & img {
      border-radius: 8px 8px 0 0;
    }
  }
  & .btnArea {
    display: flex;
    background-color: #444444;
    border-radius: 0 0 8px 8px;
    height: 48px;
    & .cBtn {
      line-height: 48px;
      align-items: center;
      flex-basis: 50%;
      color: white;
      &:first-of-type {
        border-right: 1px solid #cccccc;
      }
    }
  }
}
